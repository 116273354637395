@font-face {
	font-family: "Roboto";
	font-weight: 400;
	src: url(/fonts/Roboto-Regular.ttf) format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	font-weight: 700;
	src: url(/fonts/Roboto-Bold.ttf) format("truetype");
	font-display: swap;
}